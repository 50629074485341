<template>
  <ValidationObserver slim ref="observer" v-slot="{ valid }">
    <EuiContainer style="height: calc(100vh - 180px);">
      <EuiGrid gutters>
        <EuiGridRow>
          <EuiGridColumn>
            <div class="flex items-center">
              <EuiButton variant="text" iconOnly size="large" color="primary" @click="toSignatories">
                <EuiIcon name="chevron_left"/>
              </EuiButton>
              <EuiHeading :level="1"><b>{{ $t('signatories.external.index.create') }}</b></EuiHeading>
            </div>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
            <signatory ref="signatory" :signatory="{ signatory }" :options="{ options }"></signatory>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow v-if="canSelectArchivalOrgs">
          <EuiGridColumn>
            <linked-organizations :localSignatoryId="'create'" v-on:affectSignatoryToOrg="affectSignatoryToOrganisations"></linked-organizations>
          </EuiGridColumn>
          <EuiGridColumn></EuiGridColumn>
        </EuiGridRow>
      </EuiGrid>
    </EuiContainer>
    <EuiBottomBar :class="bottomBarStyle">
      <EuiButton color="primary" @click="toSignatories">{{$t('button.cancel')}}</EuiButton>
      <EuiButton color="primary" variant="raised" @click="createSignatory" :disabled="!valid">{{$t('button.confirm')}}</EuiButton>
    </EuiBottomBar>
  </ValidationObserver>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex';
import LinkedOrganizations from '../../linkedorganizations/LinkedOrganizations';
import Signatory from '../Signatory';
import DOMpurify from 'dompurify';

export default {
  name: 'signatories.external.create',
  data() {
    return {
      signatory: {
        entityType: 'person',
        firstname: '',
        lastname: '',
        email: '',
        mobilePhone: '',
        address: '',
        postalCode: '',
        town: '',
        logo: undefined,
        login: '',
        password: '',
        function: '',
        service: '',
        reference: '',
        userId: '',
      },
      createOrgList: [],
      deleteOrgList: [],
    };
  },
  computed: {
    ...mapGetters({
      storeSignatories: 'ModuleEdocSign/signatory/signatories',
      options: 'ModuleEdocSign/auth/options',
    }),
    ...mapState({
      isSidenavOpen: (state) => state.ModuleEdocSign.ui.isSidenavOpen,
      isMiniVariant: (state) => state.ModuleEdocSign.ui.isMiniVariant,
      isMobileView: (state) => state.application.responsive.xs,
    }),
    bottomBarStyle() {
      return {
        '-mini': !this.isMobileView && this.isSidenavOpen && this.isMiniVariant,
        '-default': this.isSidenavOpen && !this.isMiniVariant,
      };
    },
    canSelectArchivalOrgs() {
      if (this.$store.state.ModuleEdocSign.auth.profile.options && this.$store.state.ModuleEdocSign.auth.profile.options.selectCustomerOrgsArchival) {
        return this.$store.state.ModuleEdocSign.auth.profile.options.selectCustomerOrgsArchival.value === '1';
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      storeCreateSignatory: 'ModuleEdocSign/signatory/createSignatory',
      addToLinkedOrganizations: 'ModuleEdocSign/organizations/addSignatoryToOrganisations',
      delFromLinkedOrganizations: 'ModuleEdocSign/organizations/removeSignatoryFromOrganisations',
      pushAlert: 'application/pushAlert',
    }),
    createSignatory() {
      if (this.$refs.signatory.validateFormFields()) {
        // sanitize fields
        this.signatory.firstname = DOMpurify.sanitize(this.signatory.firstname);
        this.signatory.lastname = DOMpurify.sanitize(this.signatory.lastname);
        // send to back-end
        this.storeCreateSignatory(this.signatory)
          .then((response) => {
            this.updateLinkedOrganizations(response.data.userId);
            this.toSignatories();
          })
          .catch((error) => {
            if (error.data.message === 'email already in use in domain') {
              this.$refs.signatory.setInvalidField('email', true, this.$i18n.t('signatory.form.email.error.emailAlreadyUsed'));
            }
          });
      } else {
        this.pushAlert({
          message: this.$i18n.t('settings.groups.edit.error.phoneInvalid'),
          type: 'error',
        });
      }
    },
    toSignatories() {
      this.$router.push({ name: 'signatories.external.index' });
    },
    affectSignatoryToOrganisations(orgzObj) {
      this.createOrgList = orgzObj.localOrgs.filter((org) => !orgzObj.fetchedOrgs.includes(org));
      this.deleteOrgList = orgzObj.fetchedOrgs.filter((org) => !orgzObj.localOrgs.includes(org));
    },
    updateLinkedOrganizations(signId) {
      this.addToLinkedOrganizations({
        organizationIds: this.createOrgList,
        user: {
          userId: signId,
        },
      });
      this.delFromLinkedOrganizations({
        organizationIds: this.deleteOrgList,
        user: {
          userId: signId,
        },
      });
    },
  },
  components: {
    Signatory,
    LinkedOrganizations,
  },
};
</script>

<style scoped>
  .headingEdit > *{
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    vertical-align: top;
    line-height: 50px;
    margin-top: 0;
    padding-top: 0;
  }

  .headingEdit > .router-link-active {
    margin-top: 3px;
  }

  .footerEdit {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 40px;
    text-align: right;
    background-color: white;
  }
</style>
